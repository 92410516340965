<template>
  <div>
    <b-modal
      centered
      title="Add Discount"
      v-model="modal"
      size="lg"
      hide-footer
      @hidden="$emit('clearValue')"
    >
      <b-table
        class="mb-2"
        ref="tablecustom"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        show-empty
        :busy="isBusy"
        empty-text="No matching records found"
      >
        <template v-slot:cell(id)="{ item }">
          <b-button
            variant="custom-primary"
            @click="$emit('saveDiscount', item)"
            :disabled="selectId == item.promotion_ref"
          >
            {{ selectId == item.promotion_ref ? "Selected" : "Select" }}
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="changePerPage"
        @pagination="changePage"
        take="pageSize"
        :filter="filter"
        :rows="rows"
      />
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      fields: [
        {
          label: "",
          key: "id",
        },
        {
          label: "Promotion Ref.",
          key: "promotion_ref",
        },
        {
          label: "Promotions Name",
          key: "name",
        },
        {
          label: "Discount",
          key: "promotion_value",
        },
      ],
      items: [],
      loading: true,
      filter: {
        search: "",
        page: 1,
        pageSize: 5,
      },
      isBusy: false,
      rows: 0,
      selectId: 0,
    };
  },
  methods: {
    async getData(flag = false) {
      this.isBusy = true;
      if (this.items.length > 0 && !flag) return;
      this.loading = true;
      const res = await this.axios.post(
        "/transaction/list_header_discount",
        this.filter
      );
      this.isBusy = false;
      this.loading = false;
      this.items = res.data.detail.data;
      this.rows = res.data.detail.total;
    },
    show(selectId) {
      this.modal = true;
      this.selectId = selectId.ref;
      this.getData();
    },
    hide() {
      this.modal = false;
    },
    changePerPage(val) {
      this.filter.take = val;
      this.filter.page = 1;
      this.getData(true);
    },
    changePage(val) {
      this.filter.page = val;
      this.getData(true);
    },
  },
};
</script>

<style></style>
