<template>
  <div>
    <b-sidebar
      id="sidebar-condition"
      ref="sidebarCondition"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-condition-header"
      right
      backdrop
      shadow
      no-header
      width="85vw"
    >
      <b-row class="header-filter px-3">
        <b-col class="text-filter"> Edit Transaction </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="hide">x</button>
        </b-col>
      </b-row>
      <div class="p-3">
        <slot name="content"></slot>
        <!-- <template v-slot:content></template> -->
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
              :disabled="isLoading"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="warning"
              class="rounded-none"
              :disabled="isLoading"
              @click.prevent="submit(false)"
            >
              Save
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="custom-primary"
              class="rounded-none"
              :disabled="isLoading"
              @click.prevent="submit(true)"
            >
              Save & Retry
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: {
    OtherLoading,
  },
  props: {
    form: {
      required: false,
      type: Object,
    },
  },

  data() {
    return {
      isShowSidebar: false,
      isLoading: false,
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    submit(retry = false) {
      this.$emit("submit", retry);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  color: var(--font-color);
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
  padding: 2px 10px;
}
</style>
